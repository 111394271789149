import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonsModule, IconModule, InputModule, UtilsModule } from 'axis-components';
import { CountModule } from "axis-components/countDown";

import { SharedModule, AUGComponentsList } from 'axis-ui-generator';

import { CDPTextareaControlComponent } from './cdp-textarea-control.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ButtonsModule,
        IconModule,
        InputModule,
        CountModule,
        SharedModule,
        UtilsModule
    ],
    declarations: [CDPTextareaControlComponent],
    exports: [CDPTextareaControlComponent]
})

export class CDPTextareaControlModule {
    constructor() {
        AUGComponentsList['cdp-text-area'] = CDPTextareaControlComponent;
    }
 }
