import {
  Component, OnInit, OnDestroy, AfterViewChecked, Input, ViewChild, ChangeDetectionStrategy,
  ChangeDetectorRef, HostBinding
} from '@angular/core';
import { Methods } from 'axis-ui-generator';
import { FormService, SubmitService, ErrorMessages, InteractionsService, Utils } from 'axis-ui-generator';

@Component({
  selector: 'cdp-axis-aug-textarea-control',
  templateUrl: './cdp-textarea-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CDPTextareaControlComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input() uiStructure: any;
    @Input() uiData: any;
    @Input() group: any;

    @ViewChild('thisElement', { static: true }) thisElement;

    public componentInfo = {
      className: ''
    };
    public status = {
      display: true,
      disable: true
    };
    public errors = {};
    public name = '';
    public thisField;
    public timeout;
    public formMeta = '';
    public id: string;
    public errorId: string;
    public ariaDescribedby: string;

    constructor(
      private _interactionsService: InteractionsService,
      public formService: FormService,
      private _submitService: SubmitService,
      public errorMessages: ErrorMessages,
      private _cd: ChangeDetectorRef
    ) { }

    @HostBinding('class.hidden') get isHiddenMethod() {
      if (this.uiStructure.hasOwnProperty('reservedSpace') && !this.uiStructure['reservedSpace']) {
        return !this.status.display;
      }
      if (!this.formMeta.hasOwnProperty('reservedSpace')) { return false; }
      if (this.formMeta['reservedSpace'] === true) { return false; }
      return !this.status.display;
    }

    ngOnInit() {
      this.formMeta = this.formService.getFormMetadata(this.uiStructure['sectionName']);
      // Field Name
      this.uiStructure['name'] = this.formService.checkName(this.uiStructure);
      this.name = this.uiStructure['name'];
      this.formService.addField(this.uiStructure, this.uiData);
      this._interactionsService.fieldsStatus[this.name] = this.status;
      this.thisField = this.formService.Form[this.uiStructure['sectionName']].controls[this.name + ''];
      this._setAriaIds();
      this._setDefaultProperties();
      // Title
      if (!this.uiStructure.hasOwnProperty('title') ||
        (this.uiStructure.hasOwnProperty('title') && this.uiStructure['title'] + '' === 'true')
      ) {
        this.uiStructure['title'] = this.uiStructure['label'];
      } else if (this.uiStructure.hasOwnProperty('title') && this.uiStructure['title'] + '' === 'false') {
        this.uiStructure['title'] = '';
      }
      // Check status
      this._interactionsService.checkStatus(this.uiStructure, this.uiData, this.status);
      // Check Validity
      this.checkField();
      // Get Generic Errors Messages
      this.errors = this.errorMessages.replaceVars(this.uiStructure);
      // Merge with custom messages
      if (this.uiStructure.hasOwnProperty('errorMessages')) {
        for (const key in this.uiStructure.errorMessages) {
          if (this.uiStructure.errorMessages.hasOwnProperty(key)) {
            this.errors[key] = this.uiStructure.errorMessages[key];
          }
        }
      }
    }

    ngAfterViewChecked() {
      this._cd.markForCheck();
    }

    ngOnDestroy() {
      if (this._cd) {
        this._cd.detach();
      }
    }

    addOnIconClick(e) {
      this.checkField(e);
    }

    private _setAriaIds() {
      this.id = this.uiStructure.id ? this.uiStructure.id : this.uiStructure.name;
      this.errorId = this.uiStructure.errorId ? this.uiStructure.errorId : this.id + '-error';

      if (this.uiStructure.helpText && this.uiStructure.helpText.length > 0) {
        this.ariaDescribedby = this.id + '-help';
      }
    }

    /**
      * set the component default default properties
      */
  private _setDefaultProperties() {
    // Input Clear
    if (!this.uiStructure.hasOwnProperty('inputClear')) {
      this.uiStructure['inputClear'] = 'true';
    }
    // inputType
    if (!this.uiStructure.hasOwnProperty('inputType')) {
      this.uiStructure.inputType = 'text';
    }

    if (!this.uiStructure.hasOwnProperty('autocomplete')) {
      this.uiStructure['autocomplete'] = 'off';
    }
    if (!this.uiStructure.separator) {
      this.uiStructure.separator = '?';
    }

    if (this.uiStructure.addOn && this.uiStructure.addOn.clickable && !this.uiStructure.addOn.clickable.font) {
      this.uiStructure.addOn.clickable.font = 'material';
    }
  }

    onEvent(e) {
      this.checkField(e);
    }

    /**
     * Called on blur and onClear event
     * When any changes in textarea and required to emit the statusChanges event.
     */
    statusChangeEventEmit(e) {
      this.formService.Form[this.uiStructure['sectionName']].controls[this.name]
        .statusChanges.emit();
      this.checkField(e);
    }

    checkField(e?) {
      this.uiData[this.name] = this.formService.Form[this.uiStructure['sectionName']].controls[this.name].value;
      this._interactionsService.emitInteraction({
        name: this.uiStructure['name'],
        value: this.uiData[this.name]
      });
      //Emit Update
      if (this._interactionsService.hasEmitUpdate && e) {
        if (this.timeout) { clearTimeout(this.timeout); this.timeout = null; }
        this.timeout = setTimeout(() => {
          this._submitService.isUpdating({
            uiData: this.uiData,
            eventSource: this.uiStructure,
            domEvent: e
          });
          this._cd.markForCheck();
        }, this.uiStructure['timeToEmitUpdate'] ? this.uiStructure['timeToEmitUpdate'] : this._interactionsService.timeToEmitUpdate);
      }
      this.checkRequired();
      this.setAriaDescribedby();
    }

    checkRequired() {
      this.componentInfo.className = Methods.checkRequiredClass(this.uiStructure,
        this.formService.Form[this.uiStructure['sectionName']], this.componentInfo);
      if (this.uiStructure.hasOwnProperty('className') && this.componentInfo.className.indexOf(this.uiStructure.className) === -1) {
        this.componentInfo.className += this.uiStructure.className;
      }
      this._cd.markForCheck();
    }

    setAriaDescribedby() {
      const error = this.errorMessages.validate(this.thisField, this.uiStructure, this.uiData);
      if (error) {
        if (!this.ariaDescribedby || this.ariaDescribedby.indexOf(this.errorId) === -1) {
          if (this.uiStructure.ariaDescribedby) {
            this.ariaDescribedby = this.uiStructure.ariaDescribedby;
          } else {
            this.ariaDescribedby = (this.ariaDescribedby ? (this.ariaDescribedby.trim() + ' ' + this.errorId) : this.errorId);
          }
        }
      } else {
        const describedby = this.ariaDescribedby ? this.ariaDescribedby.replace(this.errorId, '') : null;
        this.ariaDescribedby = describedby ? describedby : null;
      }
      return error;
    }

    labelMenuIconEvent(eventTag) {
      if (eventTag && eventTag.indexOf('=!') > -1) {
        this._interactionsService.invertCustom(eventTag, this.uiStructure);
      } else {
        // Emit Update
        this._submitService.prepSendUpdate(eventTag, this.uiStructure);
      }
    }
  }
