<div class="axis-form-group"
  id="axis-aug-form-{{id}}"
  [formGroup]="formService.Form[uiStructure['sectionName']]"
  [class.hidden]="!status.display"
  [class.has-axis-required]="uiStructure.required && thisField.untouched && thisField.value?.length < 1"
  [class.has-axis-error]="thisField.invalid && (thisField.touched || (errorMessages.triedToSubmit ? setAriaDescribedby() : ''))"
  [ngClass]="componentInfo.className">

    <label *ngIf="uiStructure.label"
      class="axis-control-label"
      id="{{uiStructure.labelId ? uiStructure.labelId : id + '-label'}}">
        <span *ngIf="uiStructure.prefix">{{uiStructure.prefix}}</span>
        {{uiStructure.label}}{{formService.LabelSeparator}}
        <span class="axis-required-indicator" *ngIf="uiStructure.required">*</span>
        <ng-container *ngIf="uiStructure.labelMenu">
          <ng-container *ngFor="let item of uiStructure.labelMenu; trackBy : formService.trackByIcon">
            <label-menu [item]="item" (labelMenuIconEvent)="labelMenuIconEvent($event)"></label-menu>
          </ng-container>
        </ng-container>
    </label>
    <ng-container *ngIf="uiStructure.addOn; else textAreaTemplate">
      <div class="axis-input-group"
           [ngClass]="uiStructure.addOn.noSeparator ? 'axis-input-group-secondary': ''">
        <ng-container *ngIf="uiStructure.addOn.static">
          <ng-container *ngIf="uiStructure.addOn.static.placement==='left'; else addOnRight">
            <span class="axis-input-group-addon" [innerHTML]="uiStructure.addOn.static.text"></span>
            <ng-container
              *ngTemplateOutlet="textAreaTemplate">
            </ng-container>
          </ng-container>
          <ng-template #addOnRight>
            <ng-container
              *ngTemplateOutlet="textAreaTemplate">
            </ng-container>
            <span class="axis-input-group-addon" [innerHTML]="uiStructure.addOn.static.text"></span>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="uiStructure.addOn.clickable">
          <ng-container
              *ngTemplateOutlet="textAreaTemplate">
          </ng-container>
          <ng-container *ngIf="uiStructure.addOn.clickable.kind==='icon'; else buttonIcon">
            <span class="axis-input-group-addon _cursor-pointer">
              <button axis-button data-kind="icon" (click)="addOnIconClick($event);">
                <axis-icon
                  [icon]="uiStructure.addOn.clickable.icon"
                  [font]="uiStructure.addOn.clickable.font"
                  [data-color]="uiStructure.addOn.clickable.color ? uiStructure.addOn.clickable.color : ''"
                  [data-size]="uiStructure.addOn.clickable.iconSize ? uiStructure.addOn.clickable.iconSize: ''"
                  [attr.data-rotate]="uiStructure.addOn.clickable.rotate">
                  <span [data-icon]="uiStructure.addOn.clickable.icon"></span>
              </axis-icon>
              </button>
            </span>
          </ng-container>
          <ng-template #buttonIcon>
            <span class="axis-input-group-btn">
              <button axis-button
                [attr.data-kind]="uiStructure.addOn.clickable.kind ? uiStructure.addOn.clickable.kind : ''"
                [attr.data-size]="uiStructure.addOn.clickable.buttonSize ? uiStructure.addOn.clickable.buttonSize : ''"
                [attr.disabled]="uiStructure.addOn.clickable.disabled"
                (click)="addOnIconClick($event);">
                <axis-icon
                  [icon]="uiStructure.addOn.clickable.icon"
                  [font]="uiStructure.addOn.clickable.font"
                  [data-color]="uiStructure.addOn.clickable.color ? uiStructure.addOn.clickable.color : ''"
                  [data-size]="uiStructure.addOn.clickable.iconSize ? uiStructure.addOn.clickable.iconSize: ''"
                  [attr.data-rotate]="uiStructure.addOn.clickable.rotate">
                  <span [data-icon]="uiStructure.addOn.clickable.icon"></span>
              </axis-icon>
              <span class="axis-btn-icon-text">{{uiStructure.addOn.clickable.text}}</span>
              </button>
            </span>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #textAreaTemplate>
      <textarea
        formControlName="{{uiStructure['name']}}"
        [attr.id]="id"
        [attr.aria-label]="uiStructure.ariaLabel"
        [attr.aria-labelledby]="uiStructure.ariaLabelledby"
        [attr.aria-describedby]="ariaDescribedby"
        [attr.readonly]="uiStructure.readonly ? 'readonly' : null"
        [attr.viewonly]="uiStructure.viewonly ? 'viewonly' : null"
        (change)="onEvent($event)"
        (onClear)="statusChangeEventEmit($event)"
        (keyup)="onEvent($event)"
        (blur)="statusChangeEventEmit($event)"
        [detectChange]="(uiStructure.detectChange === false || uiStructure.detectChange === 'false') ? false: true"
        [placeholder]="uiStructure.placeholder ? uiStructure.placeholder : ''"
        [minlength]="uiStructure.minLength"
        [maxlength]="uiStructure.maxLength"
        [pattern]="uiStructure.pattern"
        [attr.title]="uiStructure['title']"
        [attr.disabled]="uiStructure.disabled ? 'disabled' : null"
        [rows]="uiStructure.rows"
        #thisElement
        axis-input-clear
        axis-autosize
        [ngClass]="uiStructure.componentHostClasses"
        class="axis-form-control">
      </textarea>
    </ng-template>

    <ng-container *ngIf="uiStructure.fieldMenu">
      <ng-container *ngFor="let item of uiStructure.fieldMenu; trackBy : formService.trackByIcon">
        <label-menu [item]="item" (labelMenuIconEvent)="labelMenuIconEvent($event)"></label-menu>
      </ng-container>
    </ng-container>

    <span class="axis-help-block" role="region" id="{{id + '-help'}}"
      *ngIf="uiStructure.helpText && uiStructure.helpText.length > 0">
      {{uiStructure.helpText}}
    </span>

    <ng-container *ngIf="thisField.invalid && (thisField.touched || errorMessages.triedToSubmit)">

      <span class="axis-error-block"  aria-live="assertive" id="{{errorId}}"
        *ngIf="uiStructure.required &&
          (thisField.value === null ||
            !thisField.value ||
            (thisField.value && thisField.value.length < 1))">
        {{errors['required'] || errorMessages['Errors']['required']}}
      </span>

      <span class="axis-error-block"  aria-live="assertive" id="{{errorId}}"
        *ngIf="uiStructure.minLength &&
          thisField.value &&
          thisField.value.length < uiStructure.minLength">
          {{errors['minlength'] || errorMessages['Errors'].minlength}}
      </span>

      <span class="axis-error-block"  aria-live="assertive" id="{{errorId}}"
        *ngIf="uiStructure.maxLength &&
          thisField.value &&
          thisField.value.length > uiStructure.maxLength">
          {{errors['maxlength'] || errorMessages['Errors'].maxlength}}
      </span>

      <span class="axis-error-block"  aria-live="assertive" id="{{errorId}}"
        *ngIf="uiStructure.pattern && thisField.errors && thisField.errors['pattern']">
        {{errors['pattern'] || errorMessages['Errors'].pattern}}
      </span>

      <!-- custom error message set by application. -->
      <span class="axis-error-block"  aria-live="assertive" id="{{errorId}}"
        *ngIf="thisField.errors && thisField.errors['custom']">
        {{thisField.errors['custom'].errorMessage}}
      </span>
    </ng-container>
</div>

